// COLORS
$font_color: #fff;
$border_color: #fff;
$bg_color: #282c34;
$bg_color_info_modal: #5b6477;
$social-color: #fbc820;
$music-color: #fba220;
$coding-color: #1f62a3;
$text-color: #fff;
$bg-color: #282c34;
$link-color: #61dafb;

// MEASUREMENTS
$border-radius: 0.25em;
$border: 1px solid $border_color;

$timeline-rows-length: 25; // UPDATE YEAR (CURRENT_YEAR - 1999)